import React from "react";
import DonateCard from "../components/elements/DonateCard";

function Donate() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-10 w-full py-10 px-10">
      <DonateCard title="Visitors" count={200} />
      <DonateCard title="Education" count={500} />
      <DonateCard title="Business" count={20} />
      <DonateCard title="Donation" count={100000} />
    </div>
  );
}

export default Donate;
