import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { Logo } from "../../assets";

function Footer() {
  return (
    <div
      className="bg-primary text-white mt-auto w-screen py-10 px-12"
      id="contact"
    >
      <div className="md:flex flex-col md:flex-row justify-between p-6 space-y-10 md:space-y-0">
        <div className="space-y-6 flex flex-col w-full md:w-1/3">
          <div className="flex space-x-2 items-center">
            <img alt="logo" className="h-16" src={Logo} />
            <h1 className="font-bold text-2xl text-secondary">Bread of life</h1>
          </div>
          <div className="text-white text-lg">
            <p className="text-base">
              At Bread Of Life , we are passionate about making a positive
              impact. Through community-driven projects, education, and
              sustainable development, we strive to uplift lives and create a
              better world. Join us on this journey of compassion,
              collaboration, and transformation. Together, we can build a
              brighter future for all.
            </p>
          </div>
        </div>
        <div className="text-white flex w-full md:w-1/3 justify-start md:justify-end">
          <div className="space-y-2">
            <h1 className="font-bold">Contact Us</h1>
            <h6 className="text-sm">breadoflife0346@gmail.com</h6>
            <h6 className="text-sm">+923469201220</h6>
          </div>
        </div>
        <div className="text-white flex w-full md:w-1/3 justify-start md:justify-end">
          <div className="space-y-2">
            <h1 className="font-bold">Follow Me</h1>
            <ul className="flex space-x-4 text-black">
              <li className="bg-white rounded-full p-2">
                <a
                  href="https://www.facebook.com/share/J4D6wbohYGbiXLjx/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook fontSize={"1.2rem"} className="text-primary" />
                </a>
              </li>
              <li className="bg-white rounded-full p-2">
                <a
                  href="https://www.instagram.com/breadoflife8?igsh=MXNwMDd0amU5cHpiNA%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram fontSize={"1.2rem"} className="text-primary" />
                </a>
              </li>
              <li className="bg-white rounded-full p-2">
                <a
                  href="https://api.whatsapp.com/send?phone=+923469201220&text=Hello!"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp fontSize={"1.2rem"} className="text-primary" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="px-20 pb-2"></hr>
      <div className="flex justify-center py-6">
        <p>
          Copyright By @Harryson Gulfam
          <span className="flex justify-center"></span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
