import React from "react";

function BusinessCard({ title, image }) {
  return (
    <div className="space-y-5 flex flex-col items-center justify-center">
      <img
        className="object-cover border-4 border-secondary w-80 h-64 shadow-lg rounded-lg"
        alt="profile"
        src={image}
      />
      <h6 className="text-md font-bold pb-5 text-black">{title}</h6>
    </div>
  );
}

export default BusinessCard;
