import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import { ToastContainer } from "react-toastify";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="  ">
        <div className="max-w-96">
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
