import React, { useState } from "react";

import Modal from "../elements/Modal";
import { Logo } from "../../assets";

function Navbar() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="text-white md:flex  justify-between items-center py-10 px-12 space-y-6 md:space-y-0">
      <div className="flex space-x-2 items-center">
        <img alt="logo" className="h-16" src={Logo} />
        <h1 className="font-bold text-2xl text-secondary">Bread of life</h1>
      </div>

      <ul className="text-white text-lg space-x-5 flex md:space-x-10 justify-center items-center md:mt-0 md:text-md md:right-74">
        <li>
          <a href="#home">Home</a>
        </li>
        <li>
          <a href="#vision">Vision</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
        <div className="hidden md:block">
          <button
            onClick={() => setShowModal(true)}
            className="outline-none border-none px-8 py-4 rounded-lg shadow-lg bg-secondary text-primary text-sm font-bold"
          >
            Donate
          </button>
        </div>
      </ul>
      <div className="w-full flex justify-center items-center md:hidden">
        <button
          onClick={() => setShowModal(true)}
          className="outline-none border-none px-8 py-4 rounded-lg shadow-lg bg-white text-primary text-sm font-bold"
        >
          Donate
        </button>
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <h2 className="text-2xl font-bold mb-4 text-primary">Donate Now</h2>
        <p className="mb-4 text-black">
          At Bread of life, every contribution helps us to continue our mission
          of providing education to underprivileged children and supporting
          local communities. Your donation will directly impact the lives of
          those in need and help us create a better future for everyone.
        </p>
        <p className="mb-4 text-primary font-bold">Bank Account Details:</p>
        <ul className="list-disc px-4 text-black">
          <li>Account Number: PK77 ABPA 0010 0911 1128 0027</li>
          <li>Account Name: HARRYSON GULFAM</li>
          <li>Bank Name: Allied Bank Limited - ABL</li>
        </ul>
      </Modal>
    </div>
  );
}

export default Navbar;
