import React from "react";
import { Dp } from "../../assets";

function HeaderSection() {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full py-16 px-10 h-full pb-24">
      <div className="w-full md:w-1/2 flex items-center justify-center h-full pb-10 md:pb-0">
        <div className="space-y-6 flex flex-col items-center justify-center">
          <p className="flex justify-center items-center text-center text-xl text-white text-normal italic">
            “We believe in the transformative power of collective action. Our
            mission is to uplift marginalized communities, create sustainable
            change, and empower individuals to reach their full potential.
            Through innovative programs, community engagement, and partnerships,
            we strive to make a positive impact on the lives of those we serve.”
          </p>

          <h6 className="font-bold text-white text-md text-right">
            Harryson Gulfam - Executive Director
          </h6>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex justify-center items-center h-full">
        <img
          className="object-cover bg-lightGray border-4 border-white w-96 h-full shadow-lg rounded-lg"
          alt="profile"
          src={Dp}
        />
      </div>
    </div>
    // <div className="flex flex-col md:flex-row justify-between w-full py-16 px-10 h-full pb-24">
    //   <div className="w-full md:w-1/2 flex items-center justify-center h-full pb-10 md:pb-0">
    //     <div className="space-y-6 flex flex-col items-center">
    //       <h1 className="text-white text-4xl md:text-4xl font-bold">
    //         <span className="flex justify-center items-center text-center md:ml-10">
    //           Empowering Communities, Changing Lives
    //         </span>
    //       </h1>
    //       <p className="flex justify-center items-center text-center text-xl">
    //         Join us in our journey to build a better world—one community, one
    //         life at a time. Together, we can turn challenges into opportunities
    //         and dreams into reality.
    //       </p>
    //     </div>
    //   </div>
    //   <div className="w-full md:w-1/2 flex justify-center items-center h-full">
    //     <img
    //       className="object-cover bg-lightGray border-4 border-white w-72 h-full shadow-lg rounded-lg"
    //       alt="profile"
    //       src={Dp}
    //     />
    //   </div>
    // </div>
  );
}

export default HeaderSection;
