import React from "react";

function Vision() {
  return (
    <div
      id="vision"
      className="w-full py-10 px-10 bg-gray-100 text-center my-20 shadow-lg rounded-lg"
    >
      <h2 className="text-3xl font-bold mb-4 text-secondary">Our Vision</h2>
      <h6 className="text-lg font-normal mb-4">
        Empowering Communities, Changing Lives
      </h6>
      <blockquote className="text-xl italic text-gray-700">
        "Join us in our journey to build a better world—one community, one life
        at a time. Together, we can turn challenges into opportunities and
        dreams into reality."
      </blockquote>
    </div>
  );
}

export default Vision;
