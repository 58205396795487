import React from "react";
import Navbar from "./Navbar";
import HeaderSection from "./HeaderSection";

function Header() {
  return (
    <div className="bg-primary w-screen">
      <Navbar />
      <div className="md:flex justify-center max-w-[1440px] mx-auto">
        <HeaderSection />
      </div>
    </div>
  );
}

export default Header;
