import React from "react";
import BusinessCard from "../components/elements/BusinessCard";
import { Business1, Business2, Business3, Business4 } from "../assets";

function Businesses() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-10 w-full py-10 px-10">
      <BusinessCard title="Owner of XS Graphics" image={Business4} />
      <BusinessCard title="Owner of Rachel Bridal Mexes" image={Business3} />
      <BusinessCard title="Online Ecommerce Shop" image={Business2} />
      <BusinessCard title="Owner of Undergarment Shop" image={Business1} />
    </div>
  );
}

export default Businesses;
