// src/components/Modal.js

import React from "react";

const Modal = ({ showModal, setShowModal, children }) => {
  if (!showModal) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] md:w-[50%]">
        <button
          className="absolute top-4 right-4 text-gray-700"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
