import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa6";
import { FaFileAlt, FaCheckCircle } from "react-icons/fa";

const LoanOptions = () => {
  return (
    <div className="w-full py-10 px-10 bg-gray-100 text-center my-20 shadow-lg rounded-lg">
      <div className="mb-10">
        <FaFileAlt className="text-4xl text-secondary mx-auto mb-4" />
        <h2 className="text-2xl font-semibold">Apply for Fund</h2>
        <p>Apply for Education and Business</p>
      </div>
      <div className="mb-10">
        <FaCheckCircle className="text-4xl text-secondary mx-auto mb-4" />
        <h2 className="text-2xl font-semibold">Track</h2>
        <p>Check status of your loan application</p>
      </div>
      <div className="space-y-2">
        <h1 className="font-bold">Contact us</h1>
        <ul className="flex justify-center space-x-4 text-black">
          <li className="bg-white rounded-full p-2">
            <a
              href="https://www.facebook.com/share/J4D6wbohYGbiXLjx/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook fontSize={"1.2rem"} className="text-secondary" />
            </a>
          </li>
          <li className="bg-white rounded-full p-2">
            <a
              href="https://www.instagram.com/breadoflife8?igsh=MXNwMDd0amU5cHpiNA%3D%3D&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram fontSize={"1.2rem"} className="text-secondary" />
            </a>
          </li>
          <li className="bg-white rounded-full p-2">
            <a
              href="https://api.whatsapp.com/send?phone=+923469201220&text=Hello!"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp fontSize={"1.2rem"} className="text-secondary" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LoanOptions;
