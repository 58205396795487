import React from "react";
import { FaHandsHelping } from "react-icons/fa";

function DonateCard({ title, count }) {
  return (
    <div className="bg-greenish p-10 md:p-10 space-y-10 shadow-md rounded-lg flex flex-col items-center justify-center">
      <div class="w-20 h-20 relative">
        <div class="absolute inset-0 rounded-full border-8 border-gradient-stop"></div>
        <div className="absolute mt-5 ml-5 flex items-center">
          <FaHandsHelping size={40} />
        </div>
        <div class="absolute inset-0 rounded-full border-8 border-secondary border-s-gray-200  border-gradient-complete"></div>
      </div>
      <h1 className="font-bold text-3xl">{count}+</h1>
      <label className="font-bold">{title}</label>
    </div>
  );
}

export default DonateCard;
