import React from "react";
import DonateSection from "../modules/Donate";
import Layout from "../components/layouts/Layout";
import { Education, Business } from "../assets";
import Businesses from "../modules/Businesses";
import Vision from "../modules/Vision";
import LoanOptions from "../modules/Apply";

function Homepage() {
  return (
    <Layout>
      <div className="w-screen bg-white flex justify-center">
        <div className="max-w-[1440px] mx-auto">
          <Vision />
          <div
            className={`flex flex-col md:flex-row w-full space-x-0 md:space-x-6 space-y-6 md:space-y-0 py-6 md:py-20`}
          >
            <div className="w-full md:w-1/2 flex justify-center items-center py-10">
              <img
                className="object-cover bg-lightGray border-4 border-white w-[75%] h-[100%] shadow-lg rounded-2xl"
                alt="profile"
                src={Education}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-center py-6 px-16 space-y-4">
              <h1 className="font-bold text-md">Our Education Mission</h1>
              <h1 className="font-bold text-2xl text-secondary">
                Education Scholarships: Unlocking Opportunities
              </h1>
              <p className="text-justify text-base">
                Education scholarships provide vital financial aid to students
                pursuing their academic dreams. These scholarships are awarded
                based on various factors, including academic merit, financial
                need, intended major, personal background, or extracurricular
                activities. By investing in education, scholarship programs
                empower students to overcome financial barriers and achieve
                their career goals. Whether it’s funding primary education,
                supporting higher studies, or enabling vocational training,
                scholarships play a crucial role in shaping a brighter future
                for individuals and communities alike. Explore scholarship
                opportunities and open doors to knowledge, growth, and success!
                🌟🎓💡
              </p>
              {/* <ul class="list-disc px-5 py-2 text-sm">
                <li>
                  This is a longer sentence to demonstrate the increased
                  character length.
                </li>
                <li>
                  Here's another list item with a different amount of text for
                  variation.
                </li>
                <li>
                  We can also include some symbols and punctuation for a more
                  diverse range of characters. (?!)
                </li>
                <li>
                  The final list item can be shorter to showcase the
                  flexibility.
                </li>
              </ul> */}
            </div>
          </div>
          <div
            className={`flex flex-col md:flex-row-reverse w-full space-x-0 md:space-x-6 space-y-6 md:space-y-0 py-6 md:py-20`}
          >
            <div className="w-full md:w-1/2 flex justify-center items-center py-10">
              <img
                className="object-cover bg-lightGray border-4 border-white w-[75%] h-[100%] shadow-lg rounded-2xl"
                alt="profile"
                src={Business}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-center py-6 px-16 space-y-4">
              <h1 className="font-bold text-md">
                Run your business - Change your lifestyle
              </h1>
              <h1 className="font-bold text-2xl text-secondary">
                Unlocking Opportunities for Young Entrepreneurs
              </h1>
              <p className="text-justify text-base">
                Are you a young visionary with a passion for business? Our youth
                funding programs provide essential support for budding
                entrepreneurs. From grants to mentorship, we empower you to turn
                your ideas into reality. Whether you’re launching a micro
                business, creating innovative products, or championing community
                initiatives, our funding opportunities are designed to fuel your
                dreams. Join the next generation of trailblazers and make a
                lasting impact!
              </p>
            </div>
          </div>
          <Businesses />
          <LoanOptions />

          <DonateSection />
        </div>
      </div>
    </Layout>
  );
}

export default Homepage;
